<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">

          <div class="card-body">
            <form data-vv-scope="spreadsheet">
              <div class="row">
                <!-- Trade Lane Origem -->
                <trade-lane-field
                  :grey-field="false"
                  :label="$t('form.indiceFilaImportacao.tradeLaneOrigem')"
                  name="formData.idTradeLaneOrigem"
                  v-model="formData.idTradeLaneOrigem"
                  rootClassName="col-12 col-lg-12 form-group mb-4"
                  v-validate="'required'"
                  :error="errors.first('formData.idTradeLaneOrigem')"
                />

                <!-- Trade Lane Destino -->
                <trade-lane-field
                  :grey-field="false"
                  :label="$t('form.indiceFilaImportacao.tradeLaneDestino')"
                  name="formData.idTradeLaneDestino"
                  v-model="formData.idTradeLaneDestino"
                  rootClassName="col-12 col-lg-12 form-group mb-4"
                  v-validate="'required'"
                  :error="errors.first('formData.idTradeLaneDestino')"
                />

                <!--  Attach -->
                <div class="col-md-12 form-group mt-2">
                  <custom-file-upload
                    v-model="spreadsheet.files"
                    v-show="!showProcessingMessage"
                    acceptedFiles="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx"
                    name="attach"
                    v-validate="{ required: false }"
                    :max-files="1"

                    :disabled="disabled"
                    :error="errors.first('spreadsheet.attach')">
                  </custom-file-upload>
                </div>
              </div>
            </form>
            <small>*esta operação pode demorar muito tempo, dependendo do tamanho do arquivo</small>
          </div>
        </div>
      </div>

      <!--    Actions Buttons  -->
      <div class="col-12 col-sm-12 pb-3 mb-3">
        <button
          v-show="!showProcessingMessage"
          type="button"
          class="btn btn-primary btn-fill pull-right mr-2"
          @click="onSave"
          :disabled="sendDisabled">
          Importar
        </button>
      </div><!--    Actions Buttons  -->

    </div>

    <div class="row justify-content-md-center">
      <div v-if="showProcessingMessage" class="col-12 col-md-12">
        <div class="card card-fullheight">

          <div class="card-body">
            <h6  class="mb-0">Processando a importação. Os resultados serão enviados por e-mail para o responsável.</h6>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import TariffService from '@/services/TariffService'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Form
import TradeLaneField from '@/components/Forms/TradeLaneField'
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'
import TradeLaneService from '../../../services/TradeLaneService'

export default {
  name: 'tariffForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tariffImport') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: false,
      disabled: false,
      formData: {
        idTradeLaneOrigem: null,
        idTradeLaneDestino: null
      },
      showProcessingMessage: false,
      spreadsheet: {
        files: []
      },
      importedSuccess: [],
      importedFail: []
    }
  },
  components: {
    Loading,
    CustomFileUpload,
    /* eslint-disable-next-line */
    TariffService,
    TradeLaneField
  },
  mounted () {
  },
  methods: {
    onSave () {
      let _this = this
      _this.disabled = true
      _this.showProcessingMessage = true
      this.importedSuccess = 0
      this.importedFail = []

      let data = Object.assign({}, _this.formData)

      data.idTradeLaneOrigem = data.idTradeLaneOrigem.id
      data.idTradeLaneDestino = data.idTradeLaneDestino.id

      TradeLaneService.postImportFile(this.spreadsheet, data).then(response => {
        if (response.data.result) {
          _this.$alertSwal.toast('Arquivo adicionado na fila para importação')
          _this.$router.push({ name: 'IndexImportIndex' })
        } else {
          _this.$alertSwal.toast(response.data.error, 'error')
        }
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    sync () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      TariffService.getSendImported().then(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    }
  },
  computed: {
    messages () {
      return [
        {
          title: '<h6 class="mb-0">Importados <span class="badge badge-success m-2">' + this.importedSuccess.length + '</span> </h6>',
          subtitle: '',
          lines: this.importedSuccess,
          success: true,
          index: 1,
          open: false
        },
        {
          title: '<h6 class="mb-0">Falhas <span class="badge badge-danger m-2">' + this.importedFail.length + '</span></h6>',
          subtitle: '',
          lines: this.importedFail,
          success: false,
          index: 2,
          open: false
        }
      ]
    },
    sendDisabled () {
      if (this.spreadsheet.files.length === 0) {
        return true
      }

      if (this.formData.idTradeLaneOrigem === null) {
        return true
      }

      if (this.formData.idTradeLaneDestino === null) {
        return true
      }

      return false
    },
    user () {
      return this.$store.getters.userInfo || null
    }
  }
}
</script>

<style scoped>
.swal2-popup {
  width: inherit !important;
}

.card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.md-form-control {
  height: 30px;
}

.grey-field{
  background: #fff;
  border: none;
  color: gray;
  /* font-variant: small-caps; */
}

.grey-field .selected{
  color: gray;
  text-transform: capitalize;
}
</style>
